import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import AvailabilityToolAnalytics from './AvailabilityToolAnalytics';
import PharmacyAnalytics from './PharmacyAnalytics';

const AvailabilityTool: React.FC = () => {
  return (
    <div className="p-4">
      <h1 className="mb-2 text-3xl font-bold">Live insights on your area</h1>
      <p className="mb-6 text-muted-foreground">
        Detailed view of medication shortages, pharmacy stock levels, and area health for prescribers.
      </p>

      <Tabs defaultValue="medications" className="mb-6">
        <TabsList>
          <TabsTrigger value="medications">Medications</TabsTrigger>
          <TabsTrigger value="pharmacies">Pharmacies</TabsTrigger>
        </TabsList>
        <TabsContent value="medications">
          <AvailabilityToolAnalytics />
        </TabsContent>
        <TabsContent value="pharmacies">
          <PharmacyAnalytics />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AvailabilityTool;
