import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Drawer from '@/components/Modals/Drawer';
import { ScrollArea } from '../ui/scroll-area';
import NewStaffForm from './NewGroupForm';

const NewGroupDrawer = NiceModal.create(() => {
  const modal = useModal('new-group-drawer');

  return (
    <Drawer modal={modal} title="New Pharmacy Group">
      <ScrollArea className="px-4 py-8">
        <NewStaffForm />
      </ScrollArea>
    </Drawer>
  );
});

export default NewGroupDrawer;
