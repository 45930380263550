import * as z from 'zod';

export const newPatientSchema = z.object({
  firstName: z.string().nonempty({ message: 'First Name is required' }),
  lastName: z.string().nonempty({ message: 'Last Name is required' }),
  email: z.string().email({ message: 'Invalid email address' }).nonempty({ message: 'Email is required' }),
  dob: z.string().nonempty({ message: 'Date of Birth is required' }),
  phone: z.string().nonempty({ message: 'Phone Number is required' }),
  address: z.string().nonempty({ message: 'Address is required' }),
  accountUserId: z.string().nonempty({ message: 'Select a provider for the patient' }),
});

export type NewPatientFormValues = z.infer<typeof newPatientSchema>;
