import { IMedication } from '@/types';
import { cn } from '@/utils/utils';
import Bottle from '../assets/medicons/bottle.webp';
import CapsuleIcon from '../assets/medicons/capsule.png';
import InjectablePen from '../assets/medicons/injectablepen.png';
import Patch from '../assets/medicons/patch.png';
import Syringe from '../assets/medicons/syringe.png';
import TabletIcon from '../assets/medicons/tablet.png';

interface MedIconProps {
  medication: IMedication;
  className?: string;
}

const mapping = [
  {
    name: 'Tablet',
    icon: TabletIcon,
    bg: 'bg-brand-green',
  },
  {
    name: 'Suspension',
    icon: Bottle,
    bg: 'bg-brand-green',
  },
  {
    name: 'Oral Suspension',
    icon: Bottle,
    bg: 'bg-brand-green',
  },
  {
    name: 'Oral Solution',
    icon: Bottle,
    bg: 'bg-brand-green',
  },
  {
    name: 'IR Tablet',
    icon: TabletIcon,
    bg: 'bg-brand-green',
  },
  {
    name: 'SR Tablet',
    icon: TabletIcon,
    bg: 'bg-brand-green',
  },
  {
    name: 'LA Capsule',
    icon: CapsuleIcon,
    bg: 'bg-purple-500',
  },
  {
    name: 'CD Capsule',
    icon: CapsuleIcon,
    bg: 'bg-purple-500',
  },
  {
    name: 'Chewable Tablets',
    icon: TabletIcon,
    bg: 'bg-brand-green',
  },
  {
    name: 'Capsule',
    icon: CapsuleIcon,
    bg: 'bg-purple-500',
  },
  {
    name: 'Capsules',
    icon: CapsuleIcon,
    bg: 'bg-purple-500',
  },
  {
    name: 'Syringe',
    icon: Syringe,
    bg: 'bg-purple-500',
  },
  {
    name: 'Pre-filled Pen',
    icon: InjectablePen,
    bg: 'bg-purple-500',
  },
  {
    name: 'Pre-filled Pens',
    icon: InjectablePen,
    bg: 'bg-purple-500',
  },
  {
    name: 'Pre-filled Pens (Box of 4)',
    icon: InjectablePen,
    bg: 'bg-purple-500',
  },
  {
    name: 'Pre-filled Pen (Box of 1)',
    icon: InjectablePen,
    bg: 'bg-purple-500',
  },
  {
    name: 'InjectablePen',
    icon: InjectablePen,
    bg: 'bg-purple-500',
  },
  {
    name: 'Patch',
    icon: Patch,
    bg: 'bg-purple-500',
  },
];

const MedIcon = ({ medication, className }: MedIconProps) => {
  const med = mapping.find((m) => m.name === medication.dosage_form);

  if (!med) {
    return (
      <div className={cn('flex justify-center text-center rounded-full h-10 w-10 bg-brand-green', className)}>
        <img src={TabletIcon} alt="Needle Logo" className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className={cn('flex justify-center text-center rounded-full h-10 w-10', className)}>
      <img src={med.icon} alt="Needle Logo" className="w-10 h-10" />
    </div>
  );
};

export default MedIcon;
