import { IAccountPatient } from '@/types.ts';
import { getUrl } from '@/utils/api';

function usePatients() {
  const query = getUrl(`v2/patients`);
  return {
    ...query,
    data: query.data as IAccountPatient[],
  };
}

export default usePatients;
