import { useState, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, PlusIcon, Search, X } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import usePatients from '@/hooks/usePatients';
import { IAccountPatient } from '@/types';
import Loading from '../Loading';
import Section from '../Section';
import Time from '../Time';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableCellCentered, TableHead, TableHeader, TableRow } from '../ui/table';

interface PatientProps {
  accountPatient: IAccountPatient;
}

const Patient = ({ accountPatient }: PatientProps) => {
  const navigate = useNavigate();

  const startSearch = () => {
    navigate(`/patients/${accountPatient.id}?tab=medications`);
  };

  return (
    <TableRow key={accountPatient.id}>
      <TableCell>{accountPatient.user.name}</TableCell>
      <TableCell>{accountPatient.user.email}</TableCell>
      <TableCell>{accountPatient.user.dob}</TableCell>
      <TableCell>
        <Time>{accountPatient.created_at}</Time>
      </TableCell>
      <TableCellCentered className="flex justify-center gap-2">
        <Button onClick={() => startSearch()}>Start Search</Button>
        <Button variant={'outline'} asChild>
          <Link to={`/patients/${accountPatient.id}?tab=orders`}>Profile</Link>
        </Button>
      </TableCellCentered>
    </TableRow>
  );
};

const Patients = () => {
  const patients = usePatients();
  const [search, setSearch] = useState('');
  const newPatientDrawer = useModal('new-patient-drawer');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const hasPatientParams = ['firstName', 'lastName', 'email', 'phone', 'dob'].some((param) =>
      searchParams.has(param),
    );

    if (hasPatientParams) {
      newPatientDrawer.show({
        onHide: () => {
          navigate('/patients', { replace: true });
        },
      });
    }
  }, [newPatientDrawer, navigate, location.search]);

  if (patients.isLoading || !patients.data) {
    return <Loading />;
  }

  const sortedPatients = patients.data.sort((a, b) => a.user.name.localeCompare(b.user.name));

  const filteredPatients = sortedPatients.filter((user) => user.user.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Patients</div>
      </div>

      <div className="flex flex-col-reverse justify-between gap-8 mb-8 sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">Patients</div>
          <div className="mt-1 text-sm font-normal leading-normal text-gray-500 sm:max-w-sm md:max-w-lg text-pretty">
            You can view the patients that have access to this account. Click on a patient to view more details. You can
            also add a new patient by clicking
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button
            className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap"
            onClick={() => newPatientDrawer.show()}
          >
            <PlusIcon className="w-5 h-5" />
            Add New Patient
          </Button>
        </div>
      </div>

      <div className="flex items-center w-full mb-8 overflow-hidden bg-white border border-gray-300 rounded-full shadow-sm">
        <Search className="w-5 h-5 ml-4 text-gray-400" />
        <Input
          type="text"
          placeholder="Search patients"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-grow px-3 py-2 border-none focus:ring-0 focus:outline-none"
        />
        {search.length > 0 && (
          <Button variant="ghost" size="sm" onClick={() => setSearch('')} className="mr-2">
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>

      <Section>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Patient</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>DOB</TableHead>
              <TableHead>Added on</TableHead>
              <TableHead>View</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredPatients.map((accountPatient) => (
              <Patient key={accountPatient.id} accountPatient={accountPatient} />
            ))}

            {filteredPatients.length === 0 && (
              <TableRow>
                <TableCellCentered>No patients found.</TableCellCentered>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Section>
    </div>
  );
};

export default Patients;
