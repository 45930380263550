import React, { useState } from 'react';
import { Map as ContextMap, APIProvider, AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { IPharmacyAnalytic } from '@/types';
import PharmacyIcon from '../PharmacyIcon';

interface PharmacyProfileProps {
  pharmacy: IPharmacyAnalytic;
}

const PharmacyProfile: React.FC<PharmacyProfileProps> = ({ pharmacy }) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);

  const center = {
    lat: pharmacy.latitude,
    lng: pharmacy.longitude,
  };

  return (
    <div className="p-4 bg-white rounded shadow mb-4">
      <div className="flex items-center mb-4">
        <PharmacyIcon pharmacy={pharmacy} />
        <h2 className="text-lg font-semibold ml-2">{pharmacy.name}</h2>
      </div>
      <p className="text-sm text-gray-600 mb-1">{pharmacy.address1}</p>
      <p className="text-sm text-gray-600 mb-4">
        {pharmacy.city}, {pharmacy.state} {pharmacy.zip}
      </p>
      <div className="h-48 w-full">
        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
          <ContextMap
            defaultCenter={center}
            defaultZoom={15}
            mapId={'small-map-id'}
            className="rounded-lg h-full w-full"
            gestureHandling={'none'}
            disableDefaultUI={true}
          >
            <AdvancedMarker position={center} title={pharmacy.name} onClick={() => setInfowindowOpen(!infowindowOpen)}>
              <PharmacyIcon pharmacy={pharmacy} />
            </AdvancedMarker>
            {infowindowOpen && (
              <InfoWindow position={center} maxWidth={200} onCloseClick={() => setInfowindowOpen(false)}>
                <div className="text-sm">
                  <strong>{pharmacy.name}</strong>
                  <br />
                  {pharmacy.address1}
                </div>
              </InfoWindow>
            )}
          </ContextMap>
        </APIProvider>
      </div>
    </div>
  );
};

export default PharmacyProfile;
