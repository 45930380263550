import { IAnalytic } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAnalytics() {
  const query = getUrl(`v2/analytics/medications`);
  return {
    ...query,
    data: query.data as IAnalytic[],
  };
}

export default useAnalytics;
