import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Loading from '@/components/Loading';
import Modal from '@/components/Modals/Modal';
import PharmacyResults from '@/components/Requests/PharmacyResults';
import useRequest from '@/hooks/useRequest';

interface MedicationResultsModalProps {
  requestId: string;
}

const MedicationResultsModal = NiceModal.create(({ requestId }: MedicationResultsModalProps) => {
  const modal = useModal('medication-results-modal');
  const request = useRequest(requestId as string);

  return (
    <Modal modal={modal} title={'Pharmacy Availability'} wide>
      {request?.isLoading || !request?.data ? (
        <Loading />
      ) : (
        <PharmacyResults request={request.data} medication={request.data?.medications[0]} />
      )}
    </Modal>
  );
});

export default MedicationResultsModal;
