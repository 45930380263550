import { useState } from 'react';
import posthog from 'posthog-js';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { IAccountUser } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { Switch } from '../ui/switch';

interface EditStaffFormProps {
  accountUser: IAccountUser;
}

const EditStaffForm = ({ accountUser }: EditStaffFormProps) => {
  const [firstName, setFirstName] = useState(accountUser.user.first_name);
  const [lastName, setLastName] = useState(accountUser.user.last_name);
  const [role, setRole] = useState(accountUser.role);
  const [prescriber, setPrescriber] = useState(accountUser.is_prescriber);
  const [phone, setPhone] = useState(accountUser.user.phone);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clean_phone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axiosClient.put(`/v2/account_users/${accountUser.id}`, {
        first_name: firstName,
        last_name: lastName,
        role: role,
        is_prescriber: prescriber,
        phone: clean_phone(phone),
      });

      posthog.capture('provider_staff_updated');
      window.location.reload();
    } catch (error) {
      console.error('Error saving staff:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleArchive = async () => {
    setIsSubmitting(true);

    try {
      await axiosClient.delete(`/v2/account_users/${accountUser.id}`);

      posthog.capture('provider_staff_archived');
      window.location.reload();
    } catch (error) {
      console.error('Error archiving staff:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
        <div className="gap-4 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
          <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">First Name</Label>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                required
                autoFocus
              />
            </div>
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">Last Name</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-row items-start self-stretch w-full gap-8">
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Phone Number</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full hover:">
              <div className="relative flex items-center flex-1 gap-2 grow">
                <span className="text-base text-gray-500">+1</span>
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="flex-1 pl-2 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                  placeholder="555444433333"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">What is their role?</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full">
              <Input
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="flex-1 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                placeholder="Dr, Nurse, Billing, Pediatrics etc."
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <Switch checked={prescriber} onCheckedChange={() => setPrescriber(!prescriber)} />
          <Label className="block mb-2 text-sm font-semibold text-gray-700">
            This staff user is a prescriber of medications and can be assigned as a patients caregiver
          </Label>
        </div>

        <div className="inline-flex items-center justify-between gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
          <AlertDialog>
            <AlertDialogTrigger>
              <Button disabled={isSubmitting} className="w-full sm:w-fit" variant={'destructive'}>
                {isSubmitting ? 'Saving staff...' : 'Archive Staff'}
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want archive this staff user?</AlertDialogTitle>
                <AlertDialogDescription>This will remove the staff user from the account.</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleArchive}>Archive</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <Button onClick={handleSubmit} disabled={isSubmitting} className="w-full sm:w-fit">
            {isSubmitting ? 'Saving staff...' : 'Update Staff'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditStaffForm;
