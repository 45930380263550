import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import axiosClient from '@/utils/axiosClient';

const NewGroupForm = () => {
  const modal = useModal('new-group-drawer');
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axiosClient.post('/v2/account_pharmacy_groups', {
        name: name,
        description: description,
      });

      posthog.capture('account_pharmacy_group_created');
      modal.hide();

      navigate(`/pharmacies`);
    } catch (error) {
      console.error('Error saving staff:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
        <div className="gap-4 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
          <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">Group Name</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                required
                autoFocus
              />
            </div>
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">Description</Label>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
              />
            </div>
          </div>
        </div>

        <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
          <Button onClick={handleSubmit} disabled={isSubmitting} className="w-full sm:w-fit">
            {isSubmitting ? 'Saving group...' : 'Create Group'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewGroupForm;
