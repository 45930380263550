import { CheckCircle, XCircle } from 'lucide-react';
import usePharmacyMedicationAnalytics from '@/hooks/usePharmacyMedicationAnalytics';
import { IPharmacyAnalytic } from '@/types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

interface PharmacyMedicationAvailabilityProps {
  pharmacy: IPharmacyAnalytic;
}

const PharmacyMedicationAvailability = ({ pharmacy }: PharmacyMedicationAvailabilityProps) => {
  const medicationAnalytics = usePharmacyMedicationAnalytics(pharmacy.id as string);
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Medication</TableHead>
            <TableHead>Dosage</TableHead>
            <TableHead>In Stock</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {medicationAnalytics.data?.map((medication, index) => (
            <TableRow key={index}>
              <TableCell>{medication.medication}</TableCell>
              <TableCell>{medication.dosage}</TableCell>
              <TableCell>
                {medication.found_in_stock ? (
                  <CheckCircle className="text-green-400" />
                ) : (
                  <XCircle className="text-red-400" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PharmacyMedicationAvailability;
