import React, { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
} from '@tanstack/react-table';
import { Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useAccountPharmacyGroups from '@/hooks/useAccountPharmacyGroups';
import usePharmacyAnalytics from '@/hooks/usePharmacyAnalytics';
import { IPharmacyAnalytic, IPharmacy } from '@/types';
import { getUrl } from '@/utils/api';
import Loading from '../Loading';
import Section from '../Section';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import PharmacyMedicationAvailability from './PharmacyMedicationAvailability';
import PharmacyProfile from './PharmacyProfile';

const PharmacyAnalytics: React.FC = () => {
  const analytics = usePharmacyAnalytics();
  const [selectedPharmacy, setSelectedPharmacy] = useState<IPharmacyAnalytic | null>(null);
  const [selectedPharmacyGroup, setSelectedPharmacyGroup] = useState<string>('needle-network');
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [selectedGroupPharmacies, setSelectedGroupPharmacies] = useState<IPharmacy[]>([]);
  const pharmacyGroups = useAccountPharmacyGroups();
  const selectedGroupQuery = getUrl(selectedGroupId ? `v2/account_pharmacy_groups/${selectedGroupId}` : '');

  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (selectedPharmacyGroup && selectedPharmacyGroup !== 'needle-network') {
      setSelectedGroupId(selectedPharmacyGroup);
    } else {
      setSelectedGroupId(null);
      setSelectedGroupPharmacies([]);
    }
  }, [selectedPharmacyGroup]);

  useEffect(() => {
    if (selectedGroupQuery.data?.pharmacies) {
      setSelectedGroupPharmacies(selectedGroupQuery.data.pharmacies);
    }
  }, [selectedGroupQuery.data]);

  const columns = useMemo(
    () => [
      {
        header: 'Pharmacy',
        accessorKey: 'name',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'Address',
        accessorFn: (row: IPharmacyAnalytic) => `${row.address1}`,
        id: 'address',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'City',
        accessorFn: (row: IPharmacyAnalytic) => row.city,
        id: 'city',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'State',
        accessorFn: (row: IPharmacyAnalytic) => row.state,
        id: 'state',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'Zip',
        accessorFn: (row: IPharmacyAnalytic) => row.zip,
        id: 'zip',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'In Stock',
        accessorFn: (row: IPharmacyAnalytic) => `${row.total_in_stock}/${row.total_tasks}`,
        id: 'inStock',
        cell: (info: any) => info.getValue(),
      },
    ],
    [],
  );

  const data = useMemo(() => {
    if (!analytics.data) return [];

    if (selectedPharmacyGroup && selectedPharmacyGroup !== 'needle-network' && selectedGroupPharmacies.length > 0) {
      const groupPharmacyIds = new Set(selectedGroupPharmacies.map((p) => p.id));
      return analytics.data.filter((pharmacy) => groupPharmacyIds.has(pharmacy.id));
    }

    return analytics.data;
  }, [analytics.data, selectedPharmacyGroup, selectedGroupPharmacies]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'auto',
  });

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {/* Left Side: Pharmacy Overview */}
      <div className="flex-1">
        <Section title={'Pharmacy Overview'}>
          {/* Pharmacy Group Selector */}
          <div className="mb-4">
            <UISelect value={selectedPharmacyGroup} onValueChange={setSelectedPharmacyGroup}>
              <SelectTrigger>
                <SelectValue placeholder="Select pharmacy network" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="needle-network">Needle Network</SelectItem>
                {pharmacyGroups.data?.map((group) => (
                  <SelectItem key={group.id} value={group.id}>
                    {group.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </UISelect>
          </div>

          {/* Global Search */}
          <div className="mb-4">
            <Input
              value={globalFilter ?? ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search pharmacies..."
            />
          </div>

          {/* Pharmacy Table */}
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {analytics.isLoading || !analytics.data ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={() => setSelectedPharmacy(row.original)}
                    className={
                      selectedPharmacy && selectedPharmacy.id === row.original.id
                        ? 'bg-purple-100 hover:bg-purple-150 cursor-pointer'
                        : 'hover:bg-gray-100 cursor-pointer'
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>No data.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Pagination Controls */}
          <div className="flex items-center gap-2 mt-4">
            <Button variant="outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              {'<'}
            </Button>
            <Button variant="outline" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              {'>'}
            </Button>
            <span>
              Page{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </strong>
            </span>
            <span>
              | Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="w-16 p-1 border rounded ml-2"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              className="p-1 border rounded ml-2"
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Section>
      </div>

      {/* Right Side: Pharmacy Details */}
      <div className="w-full lg:w-1/2">
        {selectedPharmacy && <PharmacyProfile key={selectedPharmacy.id} pharmacy={selectedPharmacy} />}
        <Section
          title={selectedPharmacy ? `Medication Availability for ${selectedPharmacy.name}` : 'Medication Availability'}
        >
          {selectedPharmacy ? (
            <PharmacyMedicationAvailability pharmacy={selectedPharmacy} />
          ) : (
            <p>Select a pharmacy to view medication availability.</p>
          )}
        </Section>
      </div>
    </div>
  );
};

export default PharmacyAnalytics;
