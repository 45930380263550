import { IPharmacyAnalytic } from '@/types.ts';
import { getUrl } from '@/utils/api';

function usePharmacyAnalytics() {
  const query = getUrl(`v2/analytics/pharmacies`);
  return {
    ...query,
    data: query.data as IPharmacyAnalytic[],
  };
}

export default usePharmacyAnalytics;
