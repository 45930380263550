const processingOptions = [
  {
    label: '1-2 Business Days',
    description:
      'Your request will be processed within 1-2 business days during normal pharmacy hours on a first-come, first-served basis.',
    price: 0,
    membership_price: 0,
  },
  {
    label: 'Same Day Expedited',
    description:
      'We prioritize your request and begin contacting pharmacies the same day if ordered before 3pm local time. Due to pharmacy hours, completion may extend to the next business day.',
    price: 5,
    membership_price: 0,
  },
  {
    label: 'Weekend Expedited',
    description:
      'We will contact pharmacies on Saturday and Sunday to expedite your request. Processing times may vary based on pharmacy availability.',
    price: 25,
    membership_price: 20,
  },
];

export default processingOptions;
