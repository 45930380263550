import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  X,
  MapPin,
  Building2,
  Pill,
  PackageCheck,
  ClipboardList,
  Stethoscope,
  Syringe,
  Thermometer,
  Heart,
} from 'lucide-react';
import Select from 'react-select';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useAccountPharmacyGroups from '@/hooks/useAccountPharmacyGroups';
import useAllPharmacyMedicationAnalytics from '@/hooks/useAllPharmacyMedicationAnalytics';
import useAnalytics from '@/hooks/useAnalytics';
import usePharmacyAnalytics from '@/hooks/usePharmacyAnalytics';
import usePharmacyMedicationAnalytics from '@/hooks/usePharmacyMedicationAnalytics';
import { IAnalytic, IPharmacyAnalytic, IPharmacyMedicationAnalytic, IPharmacy } from '@/types';
import { getUrl } from '@/utils/api';
import Section from '../Section';
import PharmacyMap2 from './PharmacyMap2';

const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const STOCK_STATUS_OPTIONS = [
  { label: 'Both', value: 'both' },
  { label: 'In Stock', value: 'in_stock' },
  { label: 'Out of Stock', value: 'out_of_stock' },
];

interface MedicationDetails {
  medication: string;
  dosage: number | string;
  variant: string;
  preference: string;
}

export default function AvailabilityToolAnalytics() {
  const [selectedPharmacyGroup, setSelectedPharmacyGroup] = useState<string>('needle-network');
  const [selectedState, setSelectedState] = useState<string>('UT');
  const [selectedZipCode, setSelectedZipCode] = useState('');
  const [selectedMedications, setSelectedMedications] = useState<string[]>([]);
  const [selectedStockStatus, setSelectedStockStatus] = useState('both');
  const [selectedPharmacy, setSelectedPharmacy] = useState<IPharmacyAnalytic | null>(null);
  const [selectedMedicationDetails, setSelectedMedicationDetails] = useState<MedicationDetails | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [selectedGroupPharmacies, setSelectedGroupPharmacies] = useState<IPharmacy[]>([]);

  const analytics = useAnalytics();
  const pharmacyAnalytics = usePharmacyAnalytics();
  const pharmacyMedicationAnalytics = usePharmacyMedicationAnalytics(selectedPharmacy?.id || '');
  const allPharmacyMedicationAnalytics = useAllPharmacyMedicationAnalytics();
  const pharmacyGroups = useAccountPharmacyGroups();
  const selectedGroupQuery = getUrl(selectedGroupId ? `v2/account_pharmacy_groups/${selectedGroupId}` : '');

  // Handles pharmacy group changes
  useEffect(() => {
    if (selectedPharmacyGroup && selectedPharmacyGroup !== 'needle-network') {
      setSelectedGroupId(selectedPharmacyGroup);
    } else {
      setSelectedGroupId(null);
      setSelectedGroupPharmacies([]);
    }
  }, [selectedPharmacyGroup]);

  useEffect(() => {
    if (selectedGroupQuery.data?.pharmacies) {
      setSelectedGroupPharmacies(selectedGroupQuery.data.pharmacies);
    }
  }, [selectedGroupQuery.data]);

  const medicationOptions = useMemo(() => {
    if (!analytics.data) return [];
    const medicationsSet = new Set<string>();
    analytics.data.forEach((item) => medicationsSet.add(item.medication));
    return Array.from(medicationsSet).map((med) => ({ label: med, value: med }));
  }, [analytics.data]);

  const medicationPharmacyMap = useMemo(() => {
    if (!allPharmacyMedicationAnalytics.data) return {};
    const map: Record<string, Record<string, Set<string>>> = {};
    allPharmacyMedicationAnalytics.data.forEach((item) => {
      const key = item.medication;
      const statusKey = item.found_in_stock ? 'in_stock' : 'out_of_stock';
      if (!map[key]) map[key] = { in_stock: new Set(), out_of_stock: new Set() };
      map[key][statusKey].add(item.pharmacy_id);
    });
    return map;
  }, [allPharmacyMedicationAnalytics.data]);

  const filteredPharmacies = useMemo(() => {
    if (!pharmacyAnalytics.data) return [];

    // For account pharmacy groups, show all pharmacies in the group immediately
    if (selectedPharmacyGroup && selectedPharmacyGroup !== 'needle-network' && selectedGroupPharmacies.length > 0) {
      const groupPharmacyIds = new Set(selectedGroupPharmacies.map((p) => p.id));
      const groupPharmacies = pharmacyAnalytics.data.filter((pharmacy) => groupPharmacyIds.has(pharmacy.id));

      // Then apply any additional filters if they're set
      if (selectedState || selectedZipCode || selectedMedications.length > 0 || selectedMedicationDetails) {
        return groupPharmacies.filter((pharmacy) => {
          const matchesState = !selectedState || pharmacy.state === selectedState;
          const matchesZip = !selectedZipCode || pharmacy.zip.startsWith(selectedZipCode);
          const hasSelectedMedications =
            !selectedMedications.length ||
            selectedMedications.some((med) => {
              if (!medicationPharmacyMap[med]) return false;
              if (selectedStockStatus === 'both') {
                return (
                  medicationPharmacyMap[med]['in_stock'].has(pharmacy.id) ||
                  medicationPharmacyMap[med]['out_of_stock'].has(pharmacy.id)
                );
              }
              return medicationPharmacyMap[med][selectedStockStatus]?.has(pharmacy.id);
            });
          const matchesMedicationDetails =
            !selectedMedicationDetails ||
            allPharmacyMedicationAnalytics.data?.some(
              (item) =>
                item.pharmacy_id === pharmacy.id &&
                item.medication === selectedMedicationDetails.medication &&
                item.dosage === selectedMedicationDetails.dosage &&
                item.variant === selectedMedicationDetails.variant &&
                (item.preference === selectedMedicationDetails.preference ||
                  item.preference === 'No Preference' ||
                  selectedMedicationDetails.preference === 'No Preference') &&
                (selectedStockStatus === 'both' ||
                  (selectedStockStatus === 'in_stock' && item.found_in_stock) ||
                  (selectedStockStatus === 'out_of_stock' && !item.found_in_stock)),
            );
          return matchesState && matchesZip && hasSelectedMedications && matchesMedicationDetails;
        });
      }

      // If no additional filters, return all pharmacies in the group
      return groupPharmacies;
    }

    // For Needle Network
    const hasActiveFilter =
      selectedState || selectedZipCode || selectedMedications.length > 0 || selectedMedicationDetails;
    if (!hasActiveFilter) return [];

    // Apply filters for Needle Network
    return pharmacyAnalytics.data.filter((pharmacy) => {
      const matchesState = !selectedState || pharmacy.state === selectedState;
      const matchesZip = !selectedZipCode || pharmacy.zip.startsWith(selectedZipCode);
      const hasSelectedMedications =
        !selectedMedications.length ||
        selectedMedications.some((med) => {
          if (!medicationPharmacyMap[med]) return false;
          if (selectedStockStatus === 'both') {
            return (
              medicationPharmacyMap[med]['in_stock'].has(pharmacy.id) ||
              medicationPharmacyMap[med]['out_of_stock'].has(pharmacy.id)
            );
          }
          return medicationPharmacyMap[med][selectedStockStatus]?.has(pharmacy.id);
        });
      const matchesMedicationDetails =
        !selectedMedicationDetails ||
        allPharmacyMedicationAnalytics.data?.some(
          (item) =>
            item.pharmacy_id === pharmacy.id &&
            item.medication === selectedMedicationDetails.medication &&
            item.dosage === selectedMedicationDetails.dosage &&
            item.variant === selectedMedicationDetails.variant &&
            (item.preference === selectedMedicationDetails.preference ||
              item.preference === 'No Preference' ||
              selectedMedicationDetails.preference === 'No Preference') &&
            (selectedStockStatus === 'both' ||
              (selectedStockStatus === 'in_stock' && item.found_in_stock) ||
              (selectedStockStatus === 'out_of_stock' && !item.found_in_stock)),
        );
      return matchesState && matchesZip && hasSelectedMedications && matchesMedicationDetails;
    });
  }, [
    pharmacyAnalytics.data,
    selectedPharmacyGroup,
    selectedGroupPharmacies,
    selectedState,
    selectedZipCode,
    selectedMedications,
    medicationPharmacyMap,
    selectedStockStatus,
    selectedMedicationDetails,
    allPharmacyMedicationAnalytics.data,
  ]);

  const pharmaciesToDisplay = useMemo(() => {
    if (selectedPharmacyGroup !== 'needle-network') {
      return filteredPharmacies;
    }

    const hasActiveFilter =
      selectedState || selectedZipCode || selectedMedications.length > 0 || selectedMedicationDetails;

    if (hasActiveFilter) {
      return filteredPharmacies;
    }

    // Show default state (UT) pharmacies when no additional filters are active
    return pharmacyAnalytics.data?.filter((pharmacy) => pharmacy.state === selectedState) || [];
  }, [
    filteredPharmacies,
    selectedPharmacyGroup,
    selectedState,
    selectedZipCode,
    selectedMedications,
    selectedMedicationDetails,
    pharmacyAnalytics.data,
  ]);

  const handleClearFilters = useCallback(() => {
    setSelectedMedications([]);
    setSelectedZipCode('');
    setSelectedPharmacy(null);
    setSelectedStockStatus('both');
    setSelectedMedicationDetails(null);
    setSelectedGroupPharmacies([]);
    setSelectedGroupId(null);

    // Reset pharmacy group to 'needle-network'
    setSelectedPharmacyGroup('needle-network');

    // Reset state based on pharmacy group
    setSelectedState('UT');
  }, []);

  const handlePharmacyClick = (pharmacy: IPharmacyAnalytic) => setSelectedPharmacy(pharmacy);

  const handlePharmacyGroupChange = (groupId: string) => {
    setSelectedPharmacyGroup(groupId);

    // Reset filters except for pharmacy group
    setSelectedZipCode('');
    setSelectedMedications([]);
    setSelectedStockStatus('both');
    setSelectedMedicationDetails(null);
    setSelectedPharmacy(null);

    if (groupId === 'needle-network') {
      setSelectedGroupId(null);
      setSelectedGroupPharmacies([]);
      setSelectedState('UT'); // Set state to UT when 'needle-network' is selected
    } else {
      setSelectedGroupId(groupId);
      setSelectedGroupPharmacies([]);

      setSelectedState(''); // Reset state to 'All States' when another network is selected
    }
  };

  const handleRowClick = (item: IAnalytic | IPharmacyMedicationAnalytic) => {
    const details: MedicationDetails = {
      medication: item.medication,
      dosage: 'in_stock_rate' in item ? item.dosage_original || item.dosage : item.dosage,
      variant: item.variant,
      preference: item.preference,
    };
    setSelectedMedicationDetails(details);
  };

  const renderMedicationTable = (medications: (IAnalytic | IPharmacyMedicationAnalytic)[]) => (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="whitespace-nowrap">
            <Pill className="inline-block mr-2" size={16} />
            Medication
          </TableHead>
          <TableHead className="whitespace-nowrap">
            <Syringe className="inline-block mr-2" size={16} />
            Variant
          </TableHead>
          <TableHead className="whitespace-nowrap">
            <Thermometer className="inline-block mr-2" size={16} />
            Dosage
          </TableHead>
          <TableHead className="whitespace-nowrap">
            <Heart className="inline-block mr-2" size={16} />
            Preference
          </TableHead>
          <TableHead className="whitespace-nowrap">
            <ClipboardList className="inline-block mr-2" size={16} />
            Total Contacts
          </TableHead>
          <TableHead className="whitespace-nowrap">
            <PackageCheck className="inline-block mr-2" size={16} />
            In Stock Rate / Status
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {medications.map((item, index) => {
          const isAnalytic = 'in_stock_rate' in item;
          const itemDosage = isAnalytic ? item.dosage_original || item.dosage : item.dosage;
          const isSelected =
            selectedMedicationDetails &&
            item.medication === selectedMedicationDetails.medication &&
            itemDosage === selectedMedicationDetails.dosage &&
            item.variant === selectedMedicationDetails.variant &&
            item.preference === selectedMedicationDetails.preference;

          return (
            <TableRow
              key={index}
              onClick={() => handleRowClick(item)}
              className={`cursor-pointer hover:bg-muted ${isSelected ? 'bg-muted' : ''}`}
            >
              <TableCell>{item.medication || 'N/A'}</TableCell>
              <TableCell>{item.variant || 'N/A'}</TableCell>
              <TableCell>{itemDosage || 'N/A'}</TableCell>
              <TableCell>{item.preference || 'N/A'}</TableCell>
              <TableCell>{isAnalytic ? Number(item.total_contacts) || 'N/A' : 'N/A'}</TableCell>
              <TableCell>
                {isAnalytic && item.in_stock_rate !== undefined
                  ? `${item.in_stock_rate}%`
                  : 'found_in_stock' in item
                    ? item.found_in_stock
                      ? 'In Stock'
                      : 'Out of Stock'
                    : 'N/A'}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const groupedMedications = useMemo(() => {
    if (!analytics.data) return {};
    const grouped: Record<string, IAnalytic[]> = {};
    analytics.data.forEach((item) => {
      if (selectedMedications.length && !selectedMedications.includes(item.medication)) return;
      if (!grouped[item.medication]) grouped[item.medication] = [];
      grouped[item.medication].push(item);
    });
    return grouped;
  }, [analytics.data, selectedMedications]);

  const noFiltersActive =
    selectedPharmacyGroup === 'needle-network' &&
    selectedState === 'UT' &&
    !selectedZipCode &&
    selectedMedications.length === 0 &&
    selectedStockStatus === 'both' &&
    !selectedMedicationDetails;

  return (
    <div className="space-y-8">
      {noFiltersActive && (
        <div className="p-6 bg-blue-50 border border-blue-200 text-gray-700 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-3">Welcome to the Medication Availability Tool!</h3>
          <p className="text-sm mb-3">To explore medication availability and pharmacy details:</p>
          <ol className="list-decimal list-inside text-sm mb-3 pl-4 space-y-2">
            <li>
              <span className="font-semibold">Select a Pharmacy Network</span> – Start by choosing the pharmacy network
              you’d like to view.
            </li>
            <li>
              <span className="font-semibold">Filter by Location</span> – Narrow down your search by selecting a state
              or entering a ZIP code.
            </li>
            <li>
              <span className="font-semibold">Optional Filters</span> – Use filters for specific medications and stock
              status if needed.
            </li>
            <li>
              <span className="font-semibold">View Specific Pharmacy Information</span> – Click on any pharmacy icon on
              the map to view detailed information about its stock levels and address.
            </li>
            <li>
              <span className="font-semibold">Browse Medication Availability</span> – In the "Medication Availability"
              section below, you can search for a specific medication by clicking on a row for further details.
            </li>
          </ol>
          <p className="text-sm italic">Click "Clear Filters" at any time to reset your search.</p>
        </div>
      )}

      {/* Filter Section */}
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold">Filter Medications and Pharmacies</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
          {/* 1. Pharmacy Network */}
          <div className="space-y-2">
            <Label htmlFor="pharmacyGroupFilter" className="flex items-center text-sm font-medium">
              <Building2 className="mr-2" size={16} /> Pharmacy Network
            </Label>
            <UISelect value={selectedPharmacyGroup} onValueChange={handlePharmacyGroupChange}>
              <SelectTrigger id="pharmacyGroupFilter">
                <SelectValue placeholder="Select pharmacy network" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="needle-network">Needle Network</SelectItem>
                {pharmacyGroups.data?.map((group) => (
                  <SelectItem key={group.id} value={group.id}>
                    {group.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </UISelect>
          </div>

          {/* 2. Filter by State */}
          <div className="space-y-2">
            <Label htmlFor="stateFilter" className="flex items-center text-sm font-medium">
              <Building2 className="mr-2" size={16} />
              Filter by State
            </Label>
            <UISelect value={selectedState} onValueChange={setSelectedState}>
              <SelectTrigger id="stateFilter">
                <SelectValue placeholder="Select a state" />
              </SelectTrigger>
              <SelectContent className="max-h-[300px]">
                {selectedPharmacyGroup === 'needle-network' ? (
                  <>
                    <SelectItem value="UT">UT (Default)</SelectItem>
                    {STATES.filter((state) => state !== 'UT').map((state) => (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    ))}
                  </>
                ) : (
                  <>
                    <SelectItem value="">All States</SelectItem>
                    {STATES.map((state) => (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    ))}
                  </>
                )}
              </SelectContent>
            </UISelect>
          </div>

          {/* 3. Filter by ZIP Code */}
          <div className="space-y-2">
            <Label htmlFor="zipCodeFilter" className="flex items-center text-sm font-medium">
              <MapPin className="mr-2" size={16} />
              Filter by ZIP Code
            </Label>
            <Input
              id="zipCodeFilter"
              value={selectedZipCode}
              onChange={(e) => setSelectedZipCode(e.target.value)}
              placeholder="Enter ZIP code"
            />
          </div>

          {/* 4. Select Medications */}
          <div className="space-y-2">
            <Label htmlFor="medicationFilter" className="flex items-center text-sm font-medium">
              <Pill className="mr-2" size={16} />
              Select Medications
            </Label>
            <Select
              inputId="medicationFilter"
              isMulti
              options={medicationOptions}
              value={selectedMedications.map((med) => ({ label: med, value: med }))}
              onChange={(selected) => setSelectedMedications(selected.map((option) => option.value))}
              placeholder="Medications.."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          {/* 5. Stock Status */}
          <div className="space-y-2">
            <Label htmlFor="stockStatusFilter" className="flex items-center text-sm font-medium">
              <PackageCheck className="mr-2" size={16} />
              Stock Status
            </Label>
            <UISelect value={selectedStockStatus} onValueChange={setSelectedStockStatus}>
              <SelectTrigger id="stockStatusFilter">
                <SelectValue placeholder="Select stock status" />
              </SelectTrigger>
              <SelectContent>
                {STOCK_STATUS_OPTIONS.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </UISelect>
          </div>
        </div>
      </div>

      {/* Clear Filters Button */}
      <div className="flex items-end">
        <Button onClick={handleClearFilters} variant="outline" className="w space-x-2">
          <X className="w-4 h-4" />
          <span>Clear Filters</span>
        </Button>
      </div>

      {/* Pharmacy Map */}
      <PharmacyMap2 pharmacies={pharmaciesToDisplay} onPharmacyClick={handlePharmacyClick} />

      {/* Medication Availability Section */}
      <Section className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold flex items-center">
            <Stethoscope className="mr-2" size={24} />
            Medication Availability
          </h2>
          {selectedPharmacy && (
            <div className="text-sm text-muted-foreground">Showing availability for {selectedPharmacy.name}</div>
          )}
          {selectedMedicationDetails && (
            <Button
              variant="outline"
              onClick={() => {
                setSelectedMedicationDetails(null);
                setSelectedPharmacy(null);
              }}
            >
              Show All Medications
            </Button>
          )}
        </div>
        {selectedPharmacy ? (
          pharmacyMedicationAnalytics.data ? (
            pharmacyMedicationAnalytics.data.length > 0 ? (
              renderMedicationTable(pharmacyMedicationAnalytics.data)
            ) : (
              <p className="text-center py-4">No medication data available for the selected pharmacy.</p>
            )
          ) : (
            <p className="text-center py-4">Loading pharmacy-specific medication data...</p>
          )
        ) : (
          <div className="space-y-6">
            {Object.entries(groupedMedications).length > 0 ? (
              Object.entries(groupedMedications).map(([medication, items]) => (
                <Section key={medication} title={medication}>
                  {renderMedicationTable(items)}
                </Section>
              ))
            ) : (
              <p className="text-center py-4">No medication data available.</p>
            )}
          </div>
        )}
      </Section>
    </div>
  );
}
