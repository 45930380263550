import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Clock, PhoneOutgoing } from 'lucide-react';
import posthog from 'posthog-js';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button.tsx';
import useMe from '@/hooks/useMe.ts';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import { USDollar } from '@/utils/helpers.ts';
import processingOptions from '@/utils/processingOptions';
import Section from '../Section';
import { Badge } from '../ui/badge';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface RequestSummaryProps {
  request: IRequest;
}

const RequestSummary = ({ request }: RequestSummaryProps) => {
  const currentUser = useMe();
  const isMember = currentUser?.data?.membership_is_active;

  const [isLoading, setIsLoading] = useState(false);
  const costPerPharmacy = currentUser?.data?.plan_pharmacy_cost;
  const totalMedications = request.medications.length;
  const totalPharmacies = request.pharmacies.filter((pharmacy) => {
    return !request.tasks.some((task) => task.pharmacy_id === pharmacy.id);
  }).length;
  const medicationPrice = totalPharmacies * costPerPharmacy * totalMedications;

  const submitRequest = async () => {
    setIsLoading(true);
    if (grandTotal == 0) {
      axiosClient.post(`/v1/requests/${request.id}/checkout`).then(() => {
        window.location.reload();
        setIsLoading(true);
        posthog.capture('search_started_balance', { pharmacy_count: totalPharmacies });
      });
    } else {
      try {
        const response = await axiosClient.post('/v1/credits', {
          credits: grandTotal,
          request_id: request.id,
        });
        if (response?.data?.stripe_session_id) {
          const stripe = await stripePromise;
          if (stripe) {
            await stripe.redirectToCheckout({
              sessionId: response.data.stripe_session_id,
            });
          }
        }
        posthog.capture('search_started_checkout', { pharmacy_count: totalPharmacies });
        posthog.capture('checkout_credits_balance', { credits_amount: grandTotal });
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    }
  };

  const processingTime =
    processingOptions.find((option) => option.label == request.processing_time) || processingOptions[0];

  const processingTimePrice = request.processing_time_paid
    ? 0.0
    : isMember
      ? processingTime.membership_price
      : processingTime.price;

  const subTotal = request.processing_time_paid ? medicationPrice : medicationPrice + processingTimePrice;
  const creditsAvailable = currentUser?.data?.total_credits || 0.0;
  const creditsApplicable = Math.min(creditsAvailable, subTotal);
  const total = subTotal;
  const grandTotal = total - creditsApplicable;

  return (
    <>
      <Section title="Search Summary">
        <div className="z-0 flex-1 w-full p-2 border border-gray-200 rounded-lg">
          {currentUser.isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="w-32 h-32 border-t-2 border-b-2 rounded-full animate-spin border-primary"></div>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-2 p-2">
                <div className="flex gap-2 ">
                  <Badge variant="outline" className="gap-1 px-3 py-2 rounded-md">
                    <span className="font-bold">{totalPharmacies}</span>{' '}
                    <span className="font-normal text-gray-600">Pharmacies</span>
                  </Badge>
                  <Badge variant="outline" className="gap-1 px-3 py-2 rounded-md">
                    <span className="font-bold">{totalMedications}</span>{' '}
                    <span className="font-normal text-gray-600">Medications</span>
                  </Badge>
                  <Badge variant="outline" className="gap-1 px-3 py-2 rounded-md">
                    <span className="font-bold">
                      <Clock className="w-4 h-4 " />
                    </span>{' '}
                    <span className="font-normal text-gray-600">{request.processing_time}</span>
                  </Badge>
                </div>
              </div>

              <div className="flex flex-col gap-3 p-2">
                {!request.processing_time_paid && (
                  <div className="flex justify-between gap-4 mb-2 text-sm text-gray-800">
                    <div className="flex items-center gap-1">
                      <div className="mr-1">
                        <Clock className="w-4 h-4 text-gray-500 " />
                      </div>
                      <div className="">{request.processing_time}</div>
                    </div>
                    <div className="font-semibold">{USDollar.format(processingTimePrice)}</div>
                  </div>
                )}

                {totalPharmacies > 0 &&
                  request.medications?.map((medication) => (
                    <div key={medication.id} className="flex justify-between gap-4 text-sm text-gray-800">
                      <div className="flex items-center gap-1">
                        <div className="mr-1">
                          <PhoneOutgoing className="w-4 h-4 text-gray-500 " />
                        </div>

                        <div className="">{medication.medication}</div>
                        <div className="text-gray-500 ">@ {USDollar.format(costPerPharmacy)} each</div>
                      </div>
                      <div className="font-semibold">{USDollar.format(totalPharmacies * costPerPharmacy)}</div>
                    </div>
                  ))}
              </div>

              <div className="relative mt-4">
                <div className="w-2 h-3 bg-white border-r-gray-300 border-r rounded-r-md absolute -top-[5px] -left-[11px] " />
                <div className="w-2 h-3 bg-white border-l-gray-300 border-l rounded-l-md absolute -top-[5px] -right-[11px]" />
                <div className="mt-4 border-t-2 border-dashed border-t-gray-200" />
              </div>

              <div className="flex flex-col gap-4 p-2 mt-4">
                <div className="flex gap-2 text-sm text-gray-500">
                  <div className="">Credits Available</div>
                  <div className="">{USDollar.format(creditsAvailable)}</div>
                </div>
                {creditsApplicable > 0 && (
                  <div className="flex justify-between gap-4 text-sm text-gray-800">
                    <div className="">Credits Used:</div>
                    <div className="font-semibold">{USDollar.format(creditsApplicable)}</div>
                  </div>
                )}

                <div className="flex items-center justify-between gap-4 ">
                  <div className="text-lg font-semibold text-brand-600">Your Total:</div>
                  <div className="text-2xl font-semibold">
                    {grandTotal == 0 ? (
                      <span>$0.00</span>
                    ) : (
                      <span className="text-primary">{USDollar.format(grandTotal)}</span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button
                        className="w-full rounded-full"
                        disabled={isLoading || !totalPharmacies || !totalMedications || !request.processing_time}
                      >
                        {grandTotal == 0 ? (
                          <>Start Calling Pharmacies!</>
                        ) : (
                          <>Charge me for {USDollar.format(grandTotal)} via Stripe</>
                        )}
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>What happens next?</AlertDialogTitle>
                        <AlertDialogDescription>
                          <div className="flex flex-col gap-4 mt-4 font-normal">
                            <div>
                              Based on your processing time selection, we will contact the pharmacies to fulfill your
                              request.
                            </div>
                            <div>
                              Orders submitted before 3:00 PM local time will be processed the same day. Orders
                              submitted after 3:00 PM will be processed the next business day.
                            </div>
                            <div>
                              You will receive an email notification when your order is ready to be reviewed. You can
                              also follow the status of your order.
                            </div>

                            <div>
                              By clicking "Continue" you agree to our{' '}
                              <a href="https://findneedle.co/terms" className="underline text-primary" target="_blank">
                                Terms of Service
                              </a>{' '}
                              and{' '}
                              <a
                                href="https://findneedle.co/privacy"
                                className="underline text-primary"
                                target="_blank"
                              >
                                Privacy Policy
                              </a>
                              .
                            </div>
                          </div>
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={submitRequest}>
                          {grandTotal == 0 ? (
                            <>I agree, start calling pharmacies already!</>
                          ) : (
                            <>Charge me for {USDollar.format(grandTotal)} via Stripe</>
                          )}
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </div>
            </>
          )}
        </div>
      </Section>
    </>
  );
};

export default RequestSummary;
