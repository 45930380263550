import Login from '@/components/External/Login.tsx';
import ExternalWrapper from '@/components/ExternalWrapper.tsx';
import Patients from '@/components/Patients/Patients';
import SiteWrapper from '../components/SiteWrapper';
import { useAuth } from '../providers/AuthProvider';

const Application = () => {
  const auth = useAuth();

  if (!auth) {
    return (
      <ExternalWrapper>
        <Login />
      </ExternalWrapper>
    );
  }

  return (
    <SiteWrapper>
      <Patients />
    </SiteWrapper>
  );
};

export default Application;
