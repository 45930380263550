import { IPharmacyManager } from '@/types.ts';
import { getUrl } from '@/utils/api.ts';

function usePharmacyManager({
  searchTerm,
  selectedState,
  selectedZipCode,
  currentPage,
  itemsPerPage,
}: {
  searchTerm: string;
  selectedState: string;
  selectedZipCode: string;
  currentPage: number;
  itemsPerPage: number;
}) {
  const queryParams: Record<string, string> = {};

  if (searchTerm) queryParams.query = searchTerm;
  if (selectedState) queryParams.state = selectedState;
  if (selectedZipCode) queryParams.zip_code = selectedZipCode;
  if (currentPage) queryParams.page = currentPage.toString();
  if (itemsPerPage) queryParams.per_page = itemsPerPage.toString();

  const queryString = new URLSearchParams(queryParams).toString();
  const query = getUrl(`v2/pharmacies/search?${queryString}`);

  return {
    ...query,
    data: query.data as IPharmacyManager,
  };
}

export default usePharmacyManager;
