import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Drawer from '@/components/Modals/Drawer';
import { IAccountUser } from '@/types';
import { ScrollArea } from '../ui/scroll-area';
import EditStaffForm from './EditStaffForm';

interface NewStaffDrawerProps {
  accountUser: IAccountUser;
}

const NewStaffDrawer = NiceModal.create(({ accountUser }: NewStaffDrawerProps) => {
  const modal = useModal('edit-staff-drawer');

  return (
    <Drawer modal={modal} title="Edit Staff User">
      <ScrollArea className="px-4 py-8">
        <EditStaffForm accountUser={accountUser} />
      </ScrollArea>
    </Drawer>
  );
});

export default NewStaffDrawer;
