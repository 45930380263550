import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Drawer from '@/components/Modals/Drawer';
import { ScrollArea } from '../ui/scroll-area';
import NewStaffForm from './NewStaffForm';

const NewStaffDrawer = NiceModal.create(() => {
  const modal = useModal('new-staff-drawer');

  return (
    <Drawer modal={modal} title="New Staff User">
      <ScrollArea className="px-4 py-8">
        <NewStaffForm />
      </ScrollArea>
    </Drawer>
  );
});

export default NewStaffDrawer;
