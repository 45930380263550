import { useState, useRef, useEffect } from 'react';
import {
  Map as ContextMap,
  APIProvider,
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { IPharmacy } from '@/types';
import PharmacyIcon from '../PharmacyIcon';

interface MarkerWithInfowindowProps {
  pharmacy: IPharmacy;
}

const MarkerWithInfowindow = ({ pharmacy }: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(!infowindowOpen)}
        position={{
          lat: parseFloat(String(pharmacy.latitude)),
          lng: parseFloat(String(pharmacy.longitude)),
        }}
        title={pharmacy.name}
      >
        <PharmacyIcon pharmacy={pharmacy} />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow anchor={marker} maxWidth={225} onCloseClick={() => setInfowindowOpen(false)}>
          <div className="mb-2 text-sm font-semibold text-pretty">{pharmacy.name}</div>
          <div className="text-sm text-gray-600 text-pretty">{pharmacy.address1}</div>
          <div className="text-sm text-gray-600 text-pretty">
            {pharmacy.city}, {pharmacy.zip}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

interface PharmacyMapProps {
  pharmacies: IPharmacy[];
}

const PharmacyMap = ({ pharmacies }: PharmacyMapProps) => {
  const markerRef = useRef(null);

  const [center] = useState<google.maps.LatLngLiteral>({
    lat: pharmacies[0]?.latitude || 39.8097343,
    lng: pharmacies[0]?.longitude || -98.5556199,
  });

  useEffect(() => {
    if (markerRef.current) {
      // @ts-expect-error im lazy
      markerRef.current.setPosition(center);
    }
  }, [center]);

  return (
    <>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <ContextMap
          defaultCenter={center}
          mapId={'bf51a910020fa25a'}
          className="rounded-lg h-[400px]"
          defaultZoom={4}
          gestureHandling={'greedy'}
          disableDefaultUI={false}
        >
          {pharmacies.map((pharmacy) => (
            <MarkerWithInfowindow key={pharmacy.id} pharmacy={pharmacy} />
          ))}
        </ContextMap>
      </APIProvider>
    </>
  );
};

export default PharmacyMap;
