import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { IAccountPatient } from '@/types';
import Phone from '../Phone';

interface PatientDetailsProps {
  patient: IAccountPatient;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ patient }) => {
  const modal = useModal('new-patient-drawer');

  const lastLocationCreated = patient.user.locations?.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  })[0];

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between ">
          <CardTitle>{patient.user.name ? patient.user.name : patient.user.email}</CardTitle>
          <Button
            onClick={() => {
              modal.show({ patient: patient });
            }}
            className="gap-2 btn btn-primary"
          >
            <Edit size={16} />
            Edit Patient
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Personal Information */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Personal Information</h3>
            <div className="space-y-2">
              <p>
                <strong>Email:</strong> {patient.user.email || 'N/A'}
              </p>
              <p>
                <strong>Phone:</strong> <Phone>{patient.user.phone}</Phone>
              </p>
              <p>
                <strong>Date of Birth:</strong> {patient.user.dob || 'N/A'}
              </p>
            </div>
          </div>

          {/* Address Information */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Address Information</h3>
            {lastLocationCreated ? (
              <div className="space-y-2">
                <p>
                  <strong>Address:</strong> {lastLocationCreated.address}
                </p>
                <p>
                  <strong>City:</strong> {lastLocationCreated.city}
                </p>
                <p>
                  <strong>State:</strong> {lastLocationCreated.state}
                </p>
              </div>
            ) : (
              <p>No address information available.</p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PatientDetails;
