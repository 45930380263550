import { IPharmacyMedicationAnalytic } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAllPharmacyMedicationAnalytics() {
  const query = getUrl(`v2/analytics/all_pharmacy_medications`);
  return {
    ...query,
    data: query.data as IPharmacyMedicationAnalytic[],
  };
}

export default useAllPharmacyMedicationAnalytics;
