import { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import posthog from 'posthog-js';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import useAccountUsers from '@/hooks/useAccountUsers';
import { NewPatientFormValues, newPatientSchema } from '@/schema/newPatientSchema';
import { IAccountPatient } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

interface PatientFormProps {
  patient?: IAccountPatient;
}

const PatientForm: React.FC<PatientFormProps> = ({ patient }) => {
  const modal = useModal('new-patient-drawer');
  const navigate = useNavigate();
  const accountStaff = useAccountUsers();

  const [isAddressSelected, setIsAddressSelected] = useState<boolean>(false); // New state

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const form = useForm<NewPatientFormValues>({
    resolver: zodResolver(newPatientSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      phone: '',
      accountUserId: '',
      address: '',
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (patient && patient.user) {
      setValue('firstName', patient.user.first_name || '');
      setValue('lastName', patient.user.last_name || '');
      setValue('email', patient.user.email || '');
      setValue('phone', patient.user.phone || '');
      setValue('accountUserId', patient.account_user_id);

      const lastLocationCreated = patient?.user?.locations?.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      })[0];

      const existingAddress = lastLocationCreated?.address || '';
      setValue('address', existingAddress);

      if (existingAddress) {
        setIsAddressSelected(true);
      }

      const dob = patient.user.dob ? new Date(patient.user.dob) : null;
      if (dob) {
        dob.setMinutes(dob.getMinutes() + dob.getTimezoneOffset());
        setValue('dob', dob.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
      } else {
        setValue('dob', '');
      }
    }
  }, [patient, setValue, accountStaff.data]);

  const onSubmit = async (data: NewPatientFormValues) => {
    try {
      const payload = {
        patient: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          dob: data.dob,
          phone: data.phone.replace(/\D/g, ''),
          account_user_id: data.accountUserId,
          address: data.address,
        },
      };

      let response;
      if (patient) {
        response = await axiosClient.put(`/v2/patients/${patient.id}`, payload);

        if (response.data) {
          accountStaff.refetch();
          posthog.capture('provider_patient_intake');
          window.location.reload();
        } else {
          navigate('/patients');
        }
      } else {
        response = await axiosClient.post('/v2/patients', payload);

        if (response.data && response.data.account_patient_id) {
          accountStaff.refetch();
          posthog.capture('provider_patient_intake');
          modal.hide();
          navigate(`/patients/${response.data.account_patient_id}`);
        } else {
          navigate('/patients');
        }
      }
    } catch (error: any) {
      console.error('Error saving patient:', error);
      if (error.response?.data?.message) {
        alert(
          Array.isArray(error.response.data.message)
            ? error.response.data.message.join('\n')
            : error.response.data.message,
        );
      }
    }
  };

  const handleAddressSelection = (selectedAddress: any) => {
    setValue('address', selectedAddress.description);
    setIsAddressSelected(true);
  };

  const prescribers = accountStaff?.data?.filter((staff) => staff.is_prescriber) || [];
  const showDropdown = placePredictions?.length > 0 && !isAddressSelected;

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            {/* First Name and Last Name */}
            <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
              <FormField
                control={control}
                name="firstName"
                render={({ field }) => (
                  <div className="flex-1 grow">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">First Name</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          autoFocus
                          autoComplete="off"
                        />
                      </FormControl>
                      {errors.firstName && <FormMessage>{errors.firstName.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={control}
                name="lastName"
                render={({ field }) => (
                  <div className="flex-1 grow">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Last Name</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        />
                      </FormControl>
                      {errors.lastName && <FormMessage>{errors.lastName.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>

            {/* Email Address and Address */}
            <div className="relative flex flex-row items-start self-stretch w-full gap-8">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Email Address</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          placeholder="Email Address"
                        />
                      </FormControl>
                      {errors.email && <FormMessage>{errors.email.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                control={control}
                name="address"
                render={({ field }) => (
                  <div className="relative w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Address</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          placeholder="Begin typing an address..."
                          onChange={(evt) => {
                            setValue('address', evt.target.value);
                            if (isAddressSelected) {
                              setIsAddressSelected(false);
                            }

                            getPlacePredictions({
                              input: evt.target.value,
                              types: ['address'],
                            });
                          }}
                          autoComplete="off"
                        />
                      </FormControl>

                      {showDropdown && (
                        <div className="absolute z-[9999] bg-white flex flex-col w-full border border-gray-400 divide-y rounded-md divide-gray-400 ">
                          {placePredictions.map((item) => (
                            <div
                              key={item.place_id}
                              className="px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-primary hover:text-white"
                              onClick={() => handleAddressSelection(item)}
                            >
                              {item.description}
                            </div>
                          ))}
                        </div>
                      )}
                      {errors.address && <FormMessage>{errors.address.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>
            {/* Phone Number and Date of Birth */}
            <div className="relative flex flex-row items-start self-stretch w-full gap-8">
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Phone Number</Label>
                      <FormControl>
                        <InputMask
                          mask="(999) 999-9999"
                          {...field}
                          onChange={(e) => field.onChange(e.target.value)}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        >
                          {(inputProps: any) => <Input type="tel" {...inputProps} placeholder="(555) 555-5555" />}
                        </InputMask>
                      </FormControl>
                      {errors.phone && <FormMessage>{errors.phone.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={control}
                name="dob"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Date of Birth</Label>
                      <FormControl>
                        <InputMask
                          mask="99/99/9999"
                          {...field}
                          onChange={(e) => field.onChange(e.target.value)}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        >
                          {(inputProps: any) => <Input {...inputProps} placeholder="MM/DD/YYYY" />}
                        </InputMask>
                      </FormControl>
                      {errors.dob && <FormMessage>{errors.dob.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>

            {/* Assigned Prescriber */}
            <FormField
              control={control}
              name="accountUserId"
              render={({ field }) => (
                <div className="w-full">
                  <FormItem>
                    <Label className="block mb-2 text-sm font-medium text-gray-700">Assigned Prescriber</Label>
                    <FormControl>
                      <Select onValueChange={(value) => field.onChange(value)} value={field.value}>
                        <SelectTrigger>
                          <SelectValue placeholder="Assign patient to prescriber" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="">Assign patient to prescriber</SelectItem>
                          {prescribers.map((staff: any) => (
                            <SelectItem key={staff.id} value={staff.id}>
                              {staff.user.name} ({staff.role})
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    {errors.accountUserId && <FormMessage>{errors.accountUserId.message}</FormMessage>}
                  </FormItem>
                </div>
              )}
            />

            {/* Submit Button */}
            <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
              <Button type="submit" disabled={isSubmitting} className="w-full sm:w-fit">
                {isSubmitting
                  ? patient?.id
                    ? 'Updating patient...'
                    : 'Saving patient...'
                  : patient?.id
                    ? 'Update Patient'
                    : 'Create Patient'}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default PatientForm;
