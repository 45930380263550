import { useState } from 'react';
import {
  Map as ContextMap,
  APIProvider,
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { IPharmacyAnalytic } from '@/types';
import PharmacyIcon from '../PharmacyIcon';

interface MarkerWithInfowindowProps {
  pharmacy: IPharmacyAnalytic;
  onPharmacyClick: (pharmacy: IPharmacyAnalytic) => void;
}

const MarkerWithInfowindow = ({ pharmacy, onPharmacyClick }: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleMarkerClick = () => {
    setInfowindowOpen(!infowindowOpen);
    onPharmacyClick(pharmacy);
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={handleMarkerClick}
        position={{
          lat: pharmacy.latitude,
          lng: pharmacy.longitude,
        }}
        title={pharmacy.name}
      >
        <PharmacyIcon pharmacy={pharmacy as any} />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow anchor={marker} maxWidth={225} onCloseClick={() => setInfowindowOpen(false)}>
          <div className="mb-2 text-sm font-semibold text-pretty">{pharmacy.name}</div>
          <div className="text-sm text-gray-600 text-pretty">{pharmacy.address1}</div>
          <div className="text-sm text-gray-600 text-pretty">
            {pharmacy.city}, {pharmacy.zip}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

interface PharmacyMap2Props {
  pharmacies: IPharmacyAnalytic[];
  onPharmacyClick: (pharmacy: IPharmacyAnalytic) => void;
}

const PharmacyMap2 = ({ pharmacies, onPharmacyClick }: PharmacyMap2Props) => {
  const [center] = useState<google.maps.LatLngLiteral>({
    lat: pharmacies[0]?.latitude || 39.8097343,
    lng: pharmacies[0]?.longitude || -98.5556199,
  });

  return (
    <>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <ContextMap
          defaultCenter={center}
          mapId={'bf51a910020fa25a'}
          className="rounded-lg h-[400px]"
          defaultZoom={4}
          gestureHandling={'greedy'}
          disableDefaultUI={false}
        >
          {pharmacies.map((pharmacy) => (
            <MarkerWithInfowindow key={pharmacy.id} pharmacy={pharmacy} onPharmacyClick={onPharmacyClick} />
          ))}
        </ContextMap>
      </APIProvider>
    </>
  );
};

export default PharmacyMap2;
