import { Suspense, useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import ModalRegistration from '@/components/Modals/ModalRegistration.tsx';
import { Toaster } from '@/components/ui/toaster';
import './App.css';
import { routes } from './Routes.js';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop.js';
//import AlertBannerHoliday from './components/ui/AlertBannerHoliday.js';
import { AuthProvider } from './providers/AuthProvider';

posthog.init('phc_nxbrDSUpynpf2lEMbWTmNVdQDJVvERyqkgSq7wSiSLx', {
  api_host: `${window.location.origin}/ingest`,
  ui_host: 'https://app.posthog.com',
});

ReactGA.initialize('GTM-T2P4QQ3D');

Sentry.init({
  dsn: 'https://fbfcad1c9ebc18b5b9286be9d8b8c69f@o4507051703336960.ingest.us.sentry.io/4508046181335040',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ['https://providers.findneedle.co'],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const previousUrl = localStorage.getItem('previousUrl');
    if (previousUrl) {
      localStorage.removeItem('previousUrl');
      navigate(previousUrl, { replace: true });
    }
  }, [navigate, location]);

  return (
    <>
      <Toaster />
      <NiceModal.Provider>
        <ModalRegistration />
        <ScrollToTop />
        <Routes>
          {routes.map((route: Route) => (
            <Route
              key={route.url}
              path={route.url}
              element={route.private ? <PrivateRoute>{route.element}</PrivateRoute> : <>{route.element}</>}
            />
          ))}
        </Routes>
      </NiceModal.Provider>
    </>
  );
}

function App() {
  return (
    <Suspense fallback={<></>}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AuthProvider>
              <BrowserRouter>
                <AppContent />
              </BrowserRouter>
            </AuthProvider>
          </RecoilRoot>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
