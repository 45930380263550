import { IAccountPatient } from '@/types.ts';
import { getUrl } from '@/utils/api';

function usePatient(id: string) {
  const query = getUrl(`v2/patients/${id}`);
  return {
    ...query,
    data: query.data as IAccountPatient,
  };
}

export default usePatient;
