import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Search } from 'lucide-react';
import { useParams } from 'react-router-dom';
import Loading from '@/components/Loading';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import usePatient from '@/hooks/usePatient';
import { IRequest } from '@/types';
import StatusBadge from '../StatusBadge';
import Time from '../Time';
import { Button } from '../ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

const OrderHistory: React.FC = () => {
  const modal = useModal('medication-results-modal');
  const { patientId } = useParams<{ patientId: string }>();
  const { data: patient, isLoading } = usePatient(patientId as string);

  if (isLoading) {
    return <Loading />;
  }

  if (!patient) {
    return <div>Error loading data</div>;
  }

  const requests = patient.requests || [];

  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Searches</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Processing Time</TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Results</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedRequests.length > 0 ? (
              sortedRequests.map((request: IRequest) => (
                <TableRow key={request.id}>
                  <TableCell>#{request.id.substring(0, 6).toUpperCase()}</TableCell>
                  <TableCell>
                    <StatusBadge statusDetails={request.status_details} />
                  </TableCell>

                  <TableCell>{request.processing_time}</TableCell>
                  <TableCell>
                    <Time>{request.created_at}</Time>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => modal.show({ requestId: request.id })}>
                      <Search className="w-4 h-4 mr-1" />
                      Results
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} className="text-center">
                  No orders found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default OrderHistory;
