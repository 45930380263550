import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@/components/ui/skeleton';
import usePatient from '@/hooks/usePatient';
import MedicationList from './MedicationList';
import OrderHistory from './OrderHistory';
import PatientDetails from './PatientDetails';

const PatientProfile: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const patient = usePatient(patientId as string);

  return (
    <div className="flex flex-col gap-8 ">
      <div className="w-full">
        {patient.isLoading === false && patient.data ? (
          <PatientDetails patient={patient.data} />
        ) : (
          <div className="flex flex-col space-y-3">
            <Skeleton className="h-[300px] w-full rounded-xl bg-gray-300" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        )}
      </div>
      <div className="grid w-full grid-cols-1 space-y-8 xl:space-x-8 xl:grid-cols-2 xl:space-y-0">
        <OrderHistory />
        <MedicationList patient={patient} />
      </div>
    </div>
  );
};

export default PatientProfile;
