import React, { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, PlusIcon, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import useAccountPharmacyGroups from '@/hooks/useAccountPharmacyGroups';
import Loading from '../Loading';
import Section from '../Section';
import Time from '../Time';
import { Table, TableBody, TableCell, TableCellCentered, TableHead, TableHeader, TableRow } from '../ui/table';

function Pharmacies() {
  const pharmacyGroups = useAccountPharmacyGroups();
  const [search, setSearch] = useState('');
  const drawer = useModal('new-group-drawer');

  const sortedGroups = pharmacyGroups.data?.sort((a, b) => a.name.localeCompare(b.name));
  const filteredGroups = sortedGroups?.filter((group) => group.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Pharmacy Manager</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Pharmacy Manager</div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            We allow you to manage your own pharmacy networks for your providers! Group pharmacies by location, type, or
            any other criteria you choose.
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap" onClick={() => drawer.show()}>
            <PlusIcon className="w-5 h-5" />
            Add Group
          </Button>
        </div>
      </div>

      <div className="inline-flex items-center justify-start w-full gap-1 py-1 pl-4 pr-2 mb-6 bg-white border shadow-sm rounded-2xl border-neutral-200">
        <Search className="w-5 h-5 text-neutral-500" />
        <Input
          type="text"
          placeholder="Search pharmacies"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
        />
        {search.length > 0 && (
          <Button variant="ghost" onClick={() => setSearch('')}>
            <X className="w-4 h-4 mr-1" />
          </Button>
        )}
      </div>

      {pharmacyGroups.isLoading ? (
        <Loading />
      ) : (
        <>
          <Section>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Group</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Pharmacies</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Owner</TableHead>
                  <TableHead>Manage</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredGroups.map((group) => (
                  <TableRow key={group.id}>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>{group.description}</TableCell>
                    <TableCell>{group.total_pharmacies}</TableCell>
                    <TableCell>
                      <Time>{group.created_at}</Time>
                    </TableCell>
                    <TableCell>{group.owner.name}</TableCell>
                    <TableCell>
                      <Button>
                        <Link to={`/pharmacy-groups/${group.id}`}>Manage</Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {filteredGroups.length === 0 && (
                  <TableRow>
                    <TableCellCentered>No pharmacy groups found.</TableCellCentered>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Section>
        </>
      )}
    </>
  );
}

export default Pharmacies;
