import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const Settings = () => {
  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Settings</div>
      </div>
      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-6 text-neutral-800 sm:text-2xl">Account Details</div>
          <div className="mt-2 font-normal leading-normal text-gray-500 text-md md:text-sm">
            Your account information.
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
