import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Drawer from '@/components/Modals/Drawer';
import { IAccountPatient } from '@/types';
import { ScrollArea } from '../ui/scroll-area';
import NewPatientForm from './PatientForm';

interface PatientDrawerProps {
  patient: IAccountPatient;
}

const PatientDrawer = NiceModal.create(({ patient }: PatientDrawerProps) => {
  const modal = useModal('new-patient-drawer');

  return (
    <Drawer
      modal={modal}
      title={patient ? 'Edit Patient' : 'New Patient'}
      onClose={() => {
        modal.hide();
      }}
    >
      <ScrollArea className="px-4 py-8">
        <NewPatientForm patient={patient} />
      </ScrollArea>
    </Drawer>
  );
});

export default PatientDrawer;
