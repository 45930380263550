import { IPharmacyMedicationAnalytic } from '@/types.ts';
import { getUrl } from '@/utils/api';

function usePharmacyMedicationAnalytics(id: string) {
  const query = getUrl(`v2/analytics/pharmacies/${id}`);
  return {
    ...query,
    data: query.data as IPharmacyMedicationAnalytic[],
  };
}

export default usePharmacyMedicationAnalytics;
