import { ArrowLeft } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import useAccountPharmacyGroup from '@/hooks/useAccountPharmacyGroup';
import { IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';
import Loading from '../Loading';
import PharmacyGrouper from './PharmacyGrouper';

function PharmacyGroup() {
  const { groupId } = useParams();
  const pharmacyGroup = useAccountPharmacyGroup(groupId as string);

  if (pharmacyGroup.isLoading || !pharmacyGroup.data) {
    return <Loading />;
  }

  const handleUpdate = async (pharmacy: IPharmacy) => {
    try {
      await axiosClient.post(`/v2/account_pharmacy_groups/${groupId}/toggle`, {
        pharmacy_id: pharmacy.id,
      });
    } catch (error) {
      console.error('Error saving staff:', error);
    }
  };

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/pharmacies">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Pharmacy Manager:</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">{pharmacyGroup.data.name}</div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            {pharmacyGroup.data.description}
          </div>
        </div>
      </div>

      <PharmacyGrouper pharmacyGroup={pharmacyGroup.data} onUpdate={handleUpdate} />
    </>
  );
}

export default PharmacyGroup;
