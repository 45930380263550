import { IAccountPharmacyGroup } from '@/types';
import { getUrl } from '@/utils/api';

function useAccountPharmacyGroups(includePharmacies = false) {
  const endpoint = includePharmacies ? `v2/account_pharmacy_groups?view=with_pharmacies` : `v2/account_pharmacy_groups`;

  const query = getUrl(endpoint);
  return {
    ...query,
    data: query.data as IAccountPharmacyGroup[],
  };
}

export default useAccountPharmacyGroups;
