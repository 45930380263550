import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, PlusIcon, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import useAccountUsers from '@/hooks/useAccountUsers';
import { formatPhoneNumber } from '@/utils/helpers';
import Loading from '../Loading';
import Section from '../Section';
import Time from '../Time';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableCellCentered, TableHead, TableHeader, TableRow } from '../ui/table';

const Staff = () => {
  const accountUsers = useAccountUsers();
  const newStaffDrawer = useModal('new-staff-drawer');
  const editStaffDrawer = useModal('edit-staff-drawer');
  const [search, setSearch] = useState('');

  if (accountUsers.isLoading || !accountUsers.data) {
    return <Loading />;
  }

  const sortedUsers = accountUsers.data.sort((a, b) => a.user.name.localeCompare(b.user.name));

  const filteredUsers = sortedUsers.filter((user) => user.user.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Staff</div>
      </div>

      <div className="flex flex-col-reverse justify-between gap-8 mb-8 sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">Staff Users</div>
          <div className="mt-1 text-sm font-normal leading-normal text-gray-500 sm:max-w-sm md:max-w-lg text-pretty">
            You can view the staff users that have access to this account. Click on a user to view more details. You can
            also add a new user by clicking the button below.
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button
            className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap"
            onClick={() => newStaffDrawer.show()}
          >
            <PlusIcon className="w-5 h-5" />
            Add New Staff
          </Button>
        </div>
      </div>

      <div className="flex items-center w-full mb-8 overflow-hidden bg-white border border-gray-300 rounded-full shadow-sm">
        <Search className="w-5 h-5 ml-4 text-gray-400" />
        <Input
          type="text"
          placeholder="Search staff users"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-grow px-3 py-2 border-none focus:ring-0 focus:outline-none"
        />
        {search.length > 0 && (
          <Button variant="ghost" size="sm" onClick={() => setSearch('')} className="mr-2">
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>

      <Section>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>User</TableHead>
              <TableHead>Prescriber</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Added on</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredUsers.map((accountUser) => (
              <TableRow key={accountUser.id}>
                <TableCell>{accountUser.user.name}</TableCell>
                <TableCell>{accountUser.is_prescriber ? 'Yes' : 'No'}</TableCell>
                <TableCell>{accountUser.role}</TableCell>
                <TableCell>{accountUser.user.email}</TableCell>
                <TableCell>{formatPhoneNumber(accountUser.user.phone)}</TableCell>
                <TableCell>
                  <Time>{accountUser.created_at}</Time>
                </TableCell>
                <TableCell>
                  <Button onClick={() => editStaffDrawer.show({ accountUser: accountUser })}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
            {filteredUsers.length === 0 && (
              <TableRow>
                <TableCellCentered>No staff users found.</TableCellCentered>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Section>
    </div>
  );
};

export default Staff;
