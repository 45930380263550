import { useModal } from '@ebay/nice-modal-react';
import { Progress } from '@/components/ui/progress';
import { IRequest, ITask } from '@/types';
import Section from '../Section';
import { Button } from '../ui/button';
import { StatusIndicator } from './StatusIndicator';

interface MedicationAvailabilityProps {
  request: IRequest;
}

const getStatusPriority = (
  tasks: ITask[],
): 'in_stock' | 'out_of_stock' | 'callback' | 'scheduled' | 'pending' | 'pending_approval' | string | null => {
  if (tasks.some((task) => task.stock_status === 'in_stock')) {
    return 'in_stock';
  }

  if (tasks.some((task) => task.stock_status === 'out_of_stock')) {
    return 'out_of_stock';
  }

  return '';
};

const MedicationAvailability = ({ request }: MedicationAvailabilityProps) => {
  const medicationResultsModal = useModal('medication-results-modal');
  return (
    <Section title="Medication Availability">
      <div className="flex flex-col w-full gap-4 mb-2 lg:flex-row">
        {request.medications.map((medication) => {
          const medicationTasks = request.tasks.filter((task) => task.medication_id === medication.id);
          const availability = medicationTasks.filter((task) => task.stock_status === 'in_stock');
          const availabilityPercentage = (availability.length / medicationTasks.length) * 100;

          return (
            <div
              key={medication.id}
              className="flex flex-col gap-4 p-4 bg-white border border-gray-300 max-w-1/2 rounded-xl grow"
            >
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xl font-semibold leading-6 text-gray-800 grow">{medication.medication}</div>
                  <div className="font-medium text-gray-500 text-md">{medication.dosage}</div>
                </div>
                <div className="text-gray-800">
                  <StatusIndicator status={getStatusPriority(medicationTasks)} isOrderLevel={true} />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-between text-sm font-medium text-gray-700">
                  <div className="">Availability</div>
                  <div className="">{availabilityPercentage.toFixed(0)}%</div>
                </div>
                <div className="">
                  <Progress value={availabilityPercentage} />
                </div>
                <div className="text-sm text-gray-600">
                  {availability.length} out of {medicationTasks.length} pharmacies have this medication
                </div>
              </div>

              <div className="flex flex-col justify-end h-full md:hidden">
                <Button
                  className="w-full font-semibold rounded-lg"
                  onClick={() => medicationResultsModal.show({ request: request, medication: medication })}
                >
                  View Pharmacy Results
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default MedicationAvailability;
